import React from 'react';
import Header from '../../template/admin/Header';
import { hostUrl } from '../../services/apirest';
import axios from "axios";
import launch from "../../assets/img/logo.png";

class Search extends React.Component {
  state={
    form:{
      "id":"",
      "mobile":"",
      "email":""
    },
    error:false,
    errorMsg:"",
    errorDetails:""
  }

  ctrlSubmit(e) {
    e.preventDefault();
  }

  ctrlChange = async e=> {
    await this.setState({
      form:{
        ...this.state.form,
          [e.target.name]: e.target.value
      }
    })
  }

  ctrlButton = () => {
    const url = hostUrl + "api/v1/admin/users/search";
    const { id, mobile, email } = this.state.form;

    axios.get(url, {
      headers: { 'Authorization': `${localStorage.getItem("session")}` },
      params: {
        user: {
          id,
          mobile,
          email
        }
      }
    })
      .then(response => {
        if (response.status === 200) {
          // this.setState({
          //   user: response.data.user
          // });
          // localStorage.setItem("finalUser", response.data.user.id);
          window.location.href = '/points/' + response.data.user.id + '/add';
        } else {
          this.setState({
            error: true,
            errorMsg: response.data.error.message
          })
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMsg: error.response.data.error.message,
          errorDetails: error.response.data.error.details
        });
        setTimeout(() => {
          this.setState({ error: false });
        }, 2000);
      }
    );
  };
  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        <Header></Header>
        <section className="h-100 gradient-form">
          <div className="container-fluid h-100">
            <div className="p-3">
              <div className="row h-100">
                <div className="col">
                  <div className="card rounded-3 text-black">
                    <div className="row g-0">
                      <div className="card-body p-md-5 mx-md-4">
                        <h1>Buscar usuario</h1>
                        <br/>
                        <div>
                          <form onSubmit={this.ctrlSubmit} className="form-inline">
                            <div className="form-outline mb-4">
                              <label className="form-label">Id</label>
                              <input type="text" className="form-control" name="id" placeholder="8d0d1571-32ce-4cbb-87b3-31f67ef1f480" onChange={this.ctrlChange} />
                            </div>

                            <div className="row">
                              <div className="col-md-6 mb-4">
                                <label className="form-label">Email</label>
                                <input type="text" className="form-control" name="email" placeholder="jondue@example.com" onChange={this.ctrlChange} />
                              </div>

                              <div className="col-md-6 mb-4">
                                <label className="form-label">Teléfono</label>
                                <input type="tel" className="form-control" name="mobile" placeholder="5513567429" onChange={this.ctrlChange} />
                              </div>
                            </div>
                            <br/>
                            {
                              this.state.error === true &&
                              <div className="alert alert-danger" role="alert">
                                { this.state.errorDetails }
                              </div>
                            }
                            <br/>
                            <div className="text-center">
                              <br/>
                              <input className="btn btn-primary btn-lg btn-block gradient-custom-2" type="button" value="Buscar" onClick={this.ctrlButton} />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Search