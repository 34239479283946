import React from 'react';
import '../../assets/css/Login.css';
import launch from '../../assets/img/logo.png';
import { hostUrl } from '../../services/apirest';
import axios from "axios";

class Login extends React.Component {
  state={
    form:{
      "email":"",
      "password":""
    },
    error:false,
    errorMsg:"",
    errorDetails:""
  }

  ctrlSubmit(e) {
    e.preventDefault();
  }

  ctrlChange = async e=> {
    await this.setState({
      form:{
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    })
  }

  ctrlButton = () => {
    const url = hostUrl + "api/v1/signin";
    const { email, password } = this.state.form;

    axios.post(url, {
      user: {
        email,
        password
      }
    })
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem("session", response.headers.authorization);
          if (response.data.user.role.includes('user')) {
            localStorage.setItem("userId", response.data.user.id);
            window.location.href = '/home';
          } else if (response.data.user.role.includes('admin') || response.data.user.role.includes('supervisor')) {
            window.location.href = '/search';
          }
        } else {
          console.log("Response:", response.data);
          this.setState({
            error: true,
            errorMsg: response.data.error.message
          });
        }
      })
      .catch(error => {
          console.log("Request Error:", error.response.data.error.details);
          this.setState({
            error: true,
            errorMsg: error.response.data.error.message,
            errorDetails: error.response.data.error.details
          });
        }
      );
  };


  render() {
    return (
      <React.Fragment>
        <section className="h-100 gradient-form">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-xl-10">
                <div className="card rounded-3 text-black">
                  <div className="row g-0">
                    <div className="col-lg-6">
                      <div className="card-body p-md-5 mx-md-4">

                        <div className="text-center logo-container">
                          <img src={launch} alt="logo"/>
                        </div>

                        <form onSubmit={this.ctrlSubmit}>
                          <div className="form-outline mb-4">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" placeholder="user@example.com" name="email" onChange={this.ctrlChange} />
                          </div>

                          <div className="form-outline mb-4">
                            <label className="form-label">Contraseña</label>
                            <input type="password" className="form-control"  name="password" placeholder="••••••••" onChange={this.ctrlChange} />
                          </div>

                          <div className="text-center pt-1 mb-5 pb-1">
                            <input className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button" value="Iniciar Sesión" onClick={this.ctrlButton} />
                            <br/>
                            <a className="text-muted" href="/reset-password">Olvidé mi Contraseña</a>
                          </div>

                          <div className="d-flex align-items-center justify-content-center pb-4">
                            <p className="mb-0 me-2">No tienes una cuenta?</p>
                            <a href="/signup" className="btn btn-outline-danger" role="button">Registrate</a>
                          </div>
                        </form>
                        {
                          this.state.error === true &&
                          <div className="alert alert-danger" role="alert">
                            {console.log(this.state.error)}
                            {this.state.errorDetails}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                      <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                        <h4 className="mb-4 text-center">Rewards</h4>
                        <p className="small mb-4">¡Únete a Daos Rewards y descubre un mundo de recompensas exclusivas!</p>
                        <p className="small mb-4">Como miembro de nuestro programa de lealtad, recibirás beneficios especiales por tu preferencia y fidelidad.
                          Acumula puntos con cada garrafón que llenes y canjéalos por descuentos, regalos sorprendentes y promociones irresistibles.
                          Además, disfruta de acceso privilegiado a nuestras últimas innovaciones y atención personalizada.
                          No pierdas la oportunidad de formar parte de Daos Rewards. ¡Únete ahora y comienza a disfrutar de todos los privilegios que te esperan!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Login