import React from 'react';
import '../../assets/css/Login.css';
import launch from '../../assets/img/logo.png';
import { hostUrl } from '../../services/apirest';
import axios from "axios";

class Signup extends React.Component {
  state={
    form:{
      "name":"",
      "last_name":"",
      "mobile":"",
      "email":"",
      "password":""
    },
    error:false,
    errorMsg:"",
    errorDetails:""
  }

  ctrlSubmit(e) {
		e.preventDefault();
	}

	ctrlChange = async e=> {
		await this.setState({
			form:{
				...this.state.form,
				[e.target.name]: e.target.value
			}
		})
	}

	ctrlButton = () => {
    const url = hostUrl + "api/v1/signup";
    const { name, last_name, mobile, email, password } = this.state.form;
  
    axios
      .post(url, {
        user: {
          name,
          last_name,
          mobile,
          email,
          password
        }
      })
      .then(response => {
        if (response.status === 201) {
          window.location.href = '/'
        } else {
          this.setState({
            error: true,
            errorMsg: response.data.error.details
          })
        }
      })
      .catch(error => {
        console.log("request: " + error.response.data.error.details);
        this.setState({
          error: true,
          errorMsg: error.response.data.error.message,
          errorDetails: error.response.data.error.details // Agregar detalles del error específico
        });
      }
      );
  };
  

  render() {
		return (
			<React.Fragment>
        <section className="h-100 gradient-form">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-xl-10">
                <div className="card rounded-3 text-black">
                  <div className="row g-0">
                    <div className="col-lg-6">
                      <div className="card-body p-md-5">

                        <div className="text-center logo-container">
                          <img src={launch} alt="logo"/>
                        </div>

                        <form onSubmit={this.ctrlSubmit}>
                          <div className="row">
                            <div className="col-md-6 mb-4">
                              <label className="form-label">Nombre</label>
                              <input type="text" className="form-control" name="name" onChange={this.ctrlChange} />
                            </div>

                            <div className="col-md-6 mb-4">
                              <label className="form-label">Apellido</label>
                              <input type="text" className="form-control" name="last_name" onChange={this.ctrlChange} />
                            </div>
                          </div>
                          
                          <div className="form-outline mb-4">
                            <label className="form-label">Celular</label>
                            <input type="tel" className="form-control" name="mobile" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" onChange={this.ctrlChange} />
                          </div>


                          <div className="form-outline mb-4">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" placeholder="user@example.com" name="email" onChange={this.ctrlChange} />
                          </div>

                          <div className="form-outline mb-4">
                            <label className="form-label">Contraseña</label>
                            <input type="password" className="form-control"  name="password" placeholder="••••••••" onChange={this.ctrlChange} />
                          </div>

                          <div className="text-center">
                            <input className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button" value="Guardar" onClick={this.ctrlButton} />
                            <br/>
                            <a className="text-muted" href="/">Iniciar Sesión</a>
                          </div>
                        </form>
                        {
                          this.state.error === true &&
                          <div className="alert alert-danger" role="alert">
                            {this.state.errorDetails}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                      <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                        <h4 className="mb-4 text-center">Rewards</h4>
                        <p className="small mb-4">¡Únete a Daos Rewards y descubre un mundo de recompensas exclusivas!</p>
                        <p className="small mb-4">Como miembro de nuestro programa de lealtad, recibirás beneficios especiales por tu preferencia y fidelidad.
                          Acumula puntos con cada garrafón que llenes y canjéalos por descuentos, regalos sorprendentes y promociones irresistibles.
                          Además, disfruta de acceso privilegiado a nuestras últimas innovaciones y atención personalizada.
                          No pierdas la oportunidad de formar parte de Daos Rewards. ¡Únete ahora y comienza a disfrutar de todos los privilegios que te esperan!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Signup