import React from 'react';
import Header from '../../template/user/Header';
import { hostUrl } from '../../services/apirest';
import axios from "axios";

class Rewards extends React.Component {
  state = {
    gifts: [],
    user: []
  }

  componentDidMount() {
    let giftsUrl = hostUrl + 'api/v1/public/gifts';
    let profileUrl = hostUrl + 'api/v1/users/' + localStorage.getItem('userId') + '/profile';

    axios.get(giftsUrl, {
      headers: {
        'Authorization': `${localStorage.getItem("session")}`
      }
    })
    .then(response => {
      this.setState({
        gifts: response.data.gifts
      });
    });

    axios.get(profileUrl, {
      headers: {
        'Authorization': `${localStorage.getItem("session")}`
      }
    })
    .then(response => {
      this.setState({
        user: response.data.user
      });
    });
  }

  render() {
    const { gifts, user } = this.state;

    return (
      <React.Fragment>
        <Header></Header>
        <section className="section_gap features_area">
          <div className="container">
            <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto">
              {/* <h3 className="display-4">Cuentas con { user.points } puntos</h3>
              <p className="lead">Canjea tus puntos acumulados por recompensas.</p> */}
              <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                {
                  gifts.map((value, index ) => {
                    if ( user.points >= value.points ) {
                      return(
                        <div className="col">
                          <div className="card mb-4 rounded-3 shadow-sm border-warning">
                            <div className="card-header py-3 text-white bg-warning border-warning">
                              <h4 className="my-0 fw-normal">{ value.name }</h4>
                            </div>
                            <div className="card-body">
                              <h1 className="card-title pricing-card-title">{ value.points }<small className="text-muted fw-light"> Puntos</small></h1>
                              <p>{ value.description }</p>
                              <br />
                              <button type="button" id={ value.id } className="w-100 btn btn-lg text-white btn-warning">Canjear</button>
                            </div>
                          </div>
                        </div>
                      )
                    } else {
                      return(
                        <div className="col">
                          <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                              <h4 className="my-0 fw-normal">{ value.name }</h4>
                            </div>
                            <div className="card-body">
                              <h1 className="card-title pricing-card-title">{ value.points }<small className="text-muted fw-light"> Puntos</small></h1>
                              <p>{ value.description }</p>
                              <br />
                              <button type="button" id={ value.id } className="w-100 btn btn-lg text-white btn-warning disabled">Canjear</button>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })
                }
              </div>
            </div>


          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Rewards