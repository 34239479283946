import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

import Login from "./components/login/Login";
import Signup from "./components/signup/Signup";
import Home from "./components/home/Home";
import Rewards from "./components/rewards/Rewards";
import Dashboard from "./components/dashboard/Dashboard";
import Search from "./components/search/Search";
import Add from "./components/points/Add";
import Qr from "./components/qr/Qr";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact render ={ props=> ( <Login {...props} /> ) }></Route>
          <Route path="/signup" exact render ={ props=> ( <Signup {...props} /> ) }></Route>
          <Route path="/dashboard" exact render ={ props=> ( <Dashboard {...props} /> ) }></Route>
          <Route path="/search" exact render ={ props=> ( <Search {...props} /> ) }></Route>
          <Route path="/home/" exact render ={ props=> ( <Home {...props} /> ) }></Route>
          <Route path="/rewards/" exact render ={ props=> ( <Rewards {...props} /> ) }></Route>
          <Route path="/points/:id/add" exact render ={ props=> ( <Add {...props} /> ) }></Route>
          <Route path="/qr" exact render ={ props=> ( <Qr {...props} /> ) }></Route>
        </Switch>
      </Router>
    </div>
  );
}


export default App;
