import React from 'react';
import '../../assets/css/Admin.css';
import rewards from '../../assets/img/logo.png';
import { hostUrl } from '../../services/apirest';
import axios from "axios";
import "bootstrap/js/src/collapse.js";


class Header extends React.Component {
  delete = async e=> {
    let url = hostUrl + 'api/v1/signout';

    axios.delete(url, {
      headers: {
        'Authorization': `${localStorage.getItem("session")}`
      }
    })
      .then(response =>{
        window.location.href="/";
      })
  }

  render(){
    return(
      <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
        <div className="container">
          <a className="navbar-brand"><img src={rewards} alt="Logo" /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
                  aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/search">Buscar</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/gifts">Premios</a>
              </li>
            </ul>
            <div className="d-flex">
              <button className="btn btn-outline-danger" type="button" onClick={()=>this.delete()}>Cerrar Sesión</button>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;