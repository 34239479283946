import React from 'react';
import Header from '../../template/admin/Header';
import { hostUrl } from '../../services/apirest';
import axios from "axios";

class Dashboard extends React.Component {
  
  render() {

    return (
      <React.Fragment>
        <Header></Header>
        <section className="h-100 gradient-form">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-xl-10">
                <div className="card rounded-3 text-black">
                  <div className="row g-0">
                    <div className="card-body p-md-5">
                      <h5 className="">Buscar usuario</h5>
                      <br/>
                      <form>
                        <div className="form-outline mb-4">
                          <label className="form-label">Id</label>
                          <input type="text" className="form-control" name="mobile"/>
                        </div>

                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" name="email" />
                          </div>

                          <div className="col-md-6 mb-4">
                            <label className="form-label">Teléfono</label>
                            <input type="tel" className="form-control" name="mobile" />
                          </div>
                        </div>
                        <div className="text-center">
                          <input className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button" value="Buscar" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Dashboard