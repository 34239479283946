import React, { Component } from 'react';
import Header from '../../template/admin/Header';
import { hostUrl } from '../../services/apirest';
import axios from "axios";

class Add extends Component {
  state = {
    user: {
      name: "",
      last_name: "",
      mobile: "",
      email: "",
      points: ""
    },
    form: {
      purified: "",
      osmopurified: "",
      alkaline: ""
    },
    error: false,
    errorMsg: "",
    errorDetails: ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const url = hostUrl + `api/v1/admin/users/search?id=${id}`;

    axios.get(url, {
      headers: { 'Authorization': `${localStorage.getItem("session")}` },
      params: {
        user: {
          id
        }
      }
    })
    .then(response => {
      if (response.status === 200) {
        this.setState({
          user: response.data.user
        });
      } else {
        this.setState({
          error: true,
          errorMsg: response.data.error.message
        });
      }
    })
    .catch(error => {
      this.setState({
        error: true,
        errorMsg: error.response.data.error.message,
        errorDetails: error.response.data.error.details
      });
      setTimeout(() => {
        this.setState({ error: false });
      }, 2000);
    });
  }

  ctrlSubmit = e => {
    e.preventDefault();
  };

  ctrlChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }));
  };

  ctrlButton = () => {
    const { id } = this.props.match.params;
    const url = hostUrl + `api/v1/admin/users/${id}/add`;
    const { purified, osmopurified, alkaline } = this.state.form;

    axios.post(
        url,
        {
          items: {
            purified,
            osmopurified,
            alkaline
          }
        },
        {
          headers: { Authorization: localStorage.getItem("session") }
        }
    )
    .then(response => {
      if (response.status === 201) {
        window.location.reload();
      } else {
        this.setState({
          error: true,
          errorMsg: response.data.error.details
        });
      }
    })
    .catch(error => {
      console.log("request: " + error.response.data.error.details);
      this.setState({
        error: true,
        errorMsg: error.response.data.error.message,
        errorDetails: error.response.data.error.details // Agregar detalles del error específico
      });
    });
  };

  render() {
    const { name, last_name, email, mobile, points } = this.state.user;
    return (
        <React.Fragment>
          <Header></Header>

          <section className="h-100 gradient-form">
            <div className="container-fluid h-100">
              <div className="p-3">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col h-100">
                    <div className="content">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Agregar Puntos</h4>
                              </div>
                              <div className="card-body">
                                <form onSubmit={this.ctrlSubmit} className="form-inline">
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">Agua Purificada</label>
                                    <input type="number" className="form-control" name="purified" placeholder="0" min="0" onChange={this.ctrlChange} />
                                  </div>

                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">Agua Osmopurificada</label>
                                    <input type="number" className="form-control" name="osmopurified" placeholder="0" min="0" onChange={this.ctrlChange} />
                                  </div>

                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">Agua Alcalina</label>
                                    <input type="number" className="form-control" name="alkaline" placeholder="0" min="0" onChange={this.ctrlChange} />
                                  </div>
                                  <br/>
                                  <br/>
                                  <div className="text-center">
                                    <br/>
                                    <input className="btn btn-primary btn-lg btn-block gradient-custom-2" type="button" value="Agregar Puntos" onClick={this.ctrlButton} />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4  h-100">
                            <div className="card card-user">

                              <div className="card-body">
                                <p className="text-center">
                                  <h1 className="display-1">{ points }</h1>
                                  <h6 className="card-subtitle mb-2 text-muted">Puntos</h6>
                                </p>
                                <hr/>
                                <div className="author">
                                  <h2 className="title">{ name }  { last_name }</h2>
                                  <h6 className="card-subtitle mb-2 text-muted">{ email }</h6>
                                  <h6 className="card-subtitle mb-2 text-muted">{ mobile }</h6>
                                </div>
                              </div>
                              <div className="button-container mr-auto ml-auto">
                                <button href="#" className="btn btn-simple btn-link btn-icon">
                                  <i className="fa fa-facebook-square"></i>
                                </button>
                                <button href="#" className="btn btn-simple btn-link btn-icon">
                                  <i className="fa fa-twitter"></i>
                                </button>
                                <button href="#" className="btn btn-simple btn-link pull-right btn-icon">
                                  <i className="fa fa-google-plus-square"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
    )
  }
}

export default Add