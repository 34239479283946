import React from 'react';
import Header from '../../template/user/Header';
import QRCode from "react-qr-code";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header></Header>
        <section className="h-100 gradient-form">
          <div className="container h-100 p-3">
            <div className="row d-flex justify-content-center p-3">
              <div className="col-md-6">
                <div className="card rounded-3 text-black">
                  <div className="row g-0">
                    <div className="col justify-content-center">
                      <div className="card-body">
                        <div className="pricing-header text-center">
                          <div className="text-center">
                            <p className="mb-0 text-muted">Muestra este código al personar para acumular puntos por tu compra</p>
                          </div>
                          <br/>
                          <div>
                            <QRCode
                              value={`${localStorage.getItem("userId")}`}
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                          <br/>
                          <hr/>
                          <a className="nav-link text-muted" href="/home">Regresar</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Home