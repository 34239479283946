import React from 'react';
import Header from '../../template/user/Header';
import { hostUrl } from '../../services/apirest';
import gift from '../../assets/img/gift.gif';
import axios from "axios";

class Home extends React.Component {
  state={
    user:[]
  }

  componentDidMount() {
    const userId = localStorage.getItem("userId");
    const session = localStorage.getItem("session");

    const url = `${hostUrl}api/v1/users/${userId}/profile`;
    const headers = {
      'Authorization': session
    };

    axios.get(url, { headers })
      .then(response => {
        const { name, last_name, points } = response.data.user;

        this.setState({
          user: {
            name,
            last_name,
            points
          }
        });
      });
  }

  render() {
    const user = this.state.user
    return (
      <React.Fragment>
        <Header></Header>

        <section className="h-100 gradient-form">
          <div className="container h-100 p-3">
            <div className="row d-flex justify-content-center p-3">
              <div className="col-md-6">
                <div className="card rounded-3 text-black">
                  <div className="row g-0">
                    <div className="col justify-content-center">
                      <div className="card-body">
                        <div className="pricing-header text-center">
                          <h3 className="display-4">Hola { user.name }</h3>
                          <div className="text-center logo-container">
                            <img src={gift} alt="logo"/>
                          </div>

                          <div className="row text-center">
                            <div className="col">
                              <span className="text-muted">Tu balance</span>
                              <div className="card rounded-3 shadow-sm border-warning">
                                <div className="card-header py-3 text-white bg-warning border-warning">
                                  <h2>{ user.points } Puntos</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <hr/>
                          <div className="text-center p-3">
                            <a className="nav-link text-muted" href="/qr">Suma puntos</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Home